import store from "@/store";

// Constants to be used everywhere
export const kpiSuperSensitiveLevel = 3;
export const kpiMostSensitiveLevel = 2;
export const kpiSensitiveLevel = 1;
export const kpiNonSensitiveLevel = 0;
export const readMostSensitiveKpis = "read_most_sensitive_kpi_values";
export const readSuperSensitiveKpis = "read_super_sensitive_kpi_values";
export const readSensitiveKpis = "read_sensitive_kpi_values";
export const readNonSensitiveKpis = "read_non_sensitive_kpi_values";
export const writeMostSensitiveKpis = "write_most_sensitive_kpi_values";
export const writeSuperSensitiveKpis = "write_super_sensitive_kpi_values";
export const writeSensitiveKpis = "write_sensitive_kpi_values";
export const writeNonSensitiveKpis = "write_non_sensitive_kpi_values";

/*
IMPORTANT: The allowed routes must be kept in sync with the router!
*/

const routesAllAccess = [
  "login",
  "resetPassword",
  "forgetpassword",
  "settings",
  "userManagement",
  "kpiDeepdrills",
  "uiPatterns",
  "uiPatterns2",
  "two_factor_auth",
  "react"
];

/*
Maps routes to required permissions.
Route can be accessed if user has at least one of the specified permissions.
*/
const routePermissionsMapOkr = {
  objectives: ["read_corporate_okrs", "read_employee_okrs", "read_team_okrs"],
  objectivesOverview: ["read_corporate_okrs"],
  objectivesCorporate: ["read_corporate_okrs"],
  objectivesTeams: ["read_corporate_okrs", "read_team_okrs"],
  objectivesUser: ["read_corporate_okrs", "read_team_okrs", "read_employee_okrs"],
  objectivesUserTeams: ["read_corporate_okrs", "read_team_okrs", "read_employee_okrs"],
  objectiveDetail: ["read_corporate_okrs", "read_team_okrs", "read_employee_okrs"],
  okrScheduler: ["read_corporate_okrs"],
  changeLogView: ["read_corporate_okrs", "read_team_okrs", "read_employee_okrs"],
  okrTeamAnalytics: ["read_corporate_okrs", "read_team_okrs", "read_employee_okrs"],
  okrListView: ["read_corporate_okrs", "read_team_okrs", "read_employee_okrs"]
};
const routePermissionsMap = {
  personalDashboard: ["access_my_dashboard"],
  planning: [
    "access_operational_planning",
    "access_financial_planning",
    "access_liquidity_planning",
    "read_liquidity_scenarios",
    "approve_liquidity_scenarios",
    "write_liquidity_scenarios",
    "access_liquidity_planning_admin"
  ],
  financialPlanning: ["access_financial_planning"],
  operationalPlanning: ["access_operational_planning"],
  headcountScenario: [
    "access_liquidity_planning",
    "read_liquidity_scenarios",
    "approve_liquidity_scenarios",
    "write_liquidity_scenarios",
    "access_liquidity_planning_admin"
  ],
  kpiDetail: [readMostSensitiveKpis, readSensitiveKpis, readNonSensitiveKpis],
  kpiRecommendations: [readMostSensitiveKpis, readSensitiveKpis, readNonSensitiveKpis],
  kpiCharts: [readMostSensitiveKpis, readSensitiveKpis, readNonSensitiveKpis],
  reporting: [
    "access_strategic_reporting",
    "access_operational_reporting",
    "access_financial_reporting",
    "control_custom_dashboards",
    accessCustomDashboard()
  ],
  forecastingView: ["read_forecasts"],
  operationalDashboard: ["access_operational_reporting"],
  strategicDashboard: ["access_strategic_reporting"],
  financialDashboard: ["access_financial_reporting"],
  customDashboardHelper: ["control_custom_dashboards", accessCustomDashboard()],
  customDashboard: ["control_custom_dashboards", accessCustomDashboard()],
  exportCustomDashboard: [accessCustomDashboard()],
  ...routePermissionsMapOkr,
  dataInput: ["upload_download_excel_data"],
  integration: ["manage_data_integrations"],
  integrationWizard: ["manage_data_integrations"],
  datevIntegrationWizard: ["manage_data_integrations"],
  investorReporting: ["read_investor_reporting"],
  investorReportingTab: ["read_investor_reporting"],
  oauth: []
};

const routePermissionsMapHolding = {
  holdingReporting: [accessHoldingReporting()],
  managePortfolio: [accessHoldingReporting()],
  ...routePermissionsMapOkr
};

export function currentUserCanAccessRoute(routeName) {
  // Check if everyone can access route
  if (routesAllAccess.includes(routeName)) return true;

  let userProfile = store.getters.userProfile;
  if (userProfile == null) return false;

  // select route permissions map based on instance type
  const selectedRoutePermissionsMap = userProfile.is_holding_instance
    ? routePermissionsMapHolding
    : routePermissionsMap;

  if (!Object.keys(selectedRoutePermissionsMap).includes(routeName)) return false;

  // Check if user is admin
  if (userProfile.is_staff) return true;

  // Check if user permissions match route permissions
  let routePermissions = selectedRoutePermissionsMap[routeName];

  // Check if route has no required permissions
  if (routePermissions == null || routePermissions.length === 0) return true;

  let userPermissions = store.getters.userPermissions;
  if (routePermissions == null || userPermissions == null) return false;

  return routePermissions.some(item => userPermissions.includes(item));
}

export function accessCustomDashboard() {
  // If a user can read or write into a custom dashboard, then the reporting/customDashboard routes should be available
  return "accessCustomDashboard";
}

export function accessHoldingReporting() {
  // If a user can read or write into a custom dashboard, then the reporting/customDashboard routes should be available
  return "accessHoldingReporting";
}

import { mapGetters } from "vuex";

export const PermissionsMixin = {
  name: "PermissionsMixin",
  computed: {
    ...mapGetters(["userProfile", "userPermissions"]),
    isCurrentUserAdmin() {
      return this.isUserAdmin(this.userProfile);
    },
    isHoldingInstance() {
      return this.userProfile.is_holding_instance;
    },
    currentUserCanWriteKpiValues() {
      return this.currentUserHasPermission([
        writeSuperSensitiveKpis,
        writeMostSensitiveKpis,
        writeSensitiveKpis,
        writeNonSensitiveKpis
      ]);
    },
    currentUserCanWriteOkrEmployeeLevel() {
      return this.currentUserHasPermission([
        "write_corporate_okrs",
        "write_team_okrs",
        "write_employee_okrs"
      ]);
    },
    currentUserCanWriteOkrTeamLevel() {
      return this.currentUserHasPermission(["write_corporate_okrs", "write_team_okrs"]);
    },
    currentUserCanWriteOkrCorporateLevel() {
      return this.currentUserHasPermission(["write_corporate_okrs"]);
    },
    currentUserCanReadOkrEmployeeLevel() {
      return this.currentUserHasPermission([
        "read_corporate_okrs",
        "read_team_okrs",
        "read_employee_okrs"
      ]);
    },
    currentUserCanReadOkrTeamLevel() {
      return this.currentUserHasPermission(["read_corporate_okrs", "read_team_okrs"]);
    },
    currentUserCanReadOkrCorporateLevel() {
      return this.currentUserHasPermission(["read_corporate_okrs"]);
    },
    currentUserCanAddCustomKpi() {
      return this.currentUserHasPermission(["create_custom_kpis"]);
    },
    currentUserCanControlCustomDashboards() {
      return this.currentUserHasPermission(["control_custom_dashboards"]);
    },
    currentUserCanAccessDeepDrills() {
      return this.currentUserHasPermission(["access_deep_drills"]);
    },
    currentUserCanWriteInvestorReporting() {
      return !this.isHoldingInstance && this.currentUserHasPermission(["write_investor_reporting"]);
    },
    currentUserCanAccessCopilot() {
      return this.currentUserHasPermission(["access_copilot", "access_deep_drills"]);
    },
    currentUserCanAccessLiquidityPlanning() {
      return this.currentUserHasPermission(["access_liquidity_planning"]);
    },
    currentUserCanAccessLiquidityAdminPlanning() {
      return this.currentUserHasPermission(["access_liquidity_planning_admin"]);
    },
    currentUserCanReadScenarios() {
      return this.currentUserHasPermission([
        "read_liquidity_scenarios",
        "approve_liquidity_scenarios",
        "write_liquidity_scenarios"
      ]);
    },
    currentUserCanWriteScenarios() {
      return this.currentUserHasPermission(["write_liquidity_scenarios"]);
    },
    currentUserCanApproveScenarios() {
      return this.currentUserHasPermission(["approve_liquidity_scenarios"]);
    }
  },
  methods: {
    currentUserCanAccessRoute(routeName) {
      return currentUserCanAccessRoute(routeName);
    },
    /** Check if the current user has any of the valid permissions, or an admin
     * @param {object} validPermissions               An array-like object containing the valid permissions
     * @returns                                       boolean: true if the current user has any of the valid permissions, or an admin. Otherwise, false
     */
    currentUserHasPermission(validPermissions) {
      return (
        this.isCurrentUserAdmin ||
        this.userHasAnyValidPermission(this.userPermissions, validPermissions)
      );
    },
    /** Check if any of the valid permissions exists in the user permissions
     * @param {object} userPermissions                An array-like object containing the user permissions
     * @param {object} validPermissions               An array-like object containing the valid permissions
     * @returns                                       boolean: true if the user has any of the valid permissions. Otherwise, false
     */
    userHasAnyValidPermission(userPermissions, validPermissions) {
      return validPermissions.some(permission => userPermissions.includes(permission));
    },
    /** Function to check if a user is an admin
     * @param {object} user                           A user object
     * @returns                                       boolean: true if the user is an admin. Otherwise, false
     */
    isUserAdmin(user) {
      return user?.is_staff ? true : false;
    },
    /** Function to check if a user can access a corporate objective detail view
     * @param {object} user                           A user object
     * @param {object} objective                      An OKR objective
     * @returns                                       boolean: true if the user can access. Otherwise, false
     */
    canUserAccessCorporateOkrDetailView(user, objective) {
      if (this.isUserAdmin(user)) return true;
      return (
        user.permissions.includes("read_corporate_okrs") ||
        ((user.permissions.includes("read_team_okrs") ||
          user.permissions.includes("read_employee_okrs")) &&
          user.id === objective.responsibleUser?.id)
      );
    },
    /** Function to check if a user can access a teams objective detail view
     * @param {object} user                           A user object
     * @param {object} objective                      An OKR objective
     * @returns                                       boolean: true if the user can access. Otherwise, false
     */
    canUserAccessTeamsOkrDetailView(user, objective) {
      if (this.isUserAdmin(user)) return true;
      return (
        user.permissions.includes("read_corporate_okrs") ||
        user.permissions.includes("read_team_okrs") ||
        (user.permissions.includes("read_employee_okrs") &&
          user.id === objective.responsibleUser?.id)
      );
    },
    /** Function to check if a user can access a KPI
     * @param {object} user                           A user object
     * @param {number} kpiSensitivityLevel            The KPI sensitivity level to check against
     * @param {object} userPermissions                Array-like object containing the user permissions
     * @returns                                       boolean: true if the user can access. Otherwise, false
     */
    canUserAccessKpiSensitivityLevel(user, kpiSensitivityLevel, userPermissions) {
      if (this.isUserAdmin(user)) return true;
      if (kpiSensitivityLevel == kpiSuperSensitiveLevel) {
        return this.userHasAnyValidPermission(userPermissions, [readSuperSensitiveKpis]);
      } else if (kpiSensitivityLevel === kpiMostSensitiveLevel) {
        return this.userHasAnyValidPermission(userPermissions, [readMostSensitiveKpis]);
      } else if (kpiSensitivityLevel === kpiSensitiveLevel) {
        return this.userHasAnyValidPermission(userPermissions, [
          readMostSensitiveKpis,
          readSensitiveKpis
        ]);
      } else if (kpiSensitivityLevel === kpiNonSensitiveLevel) {
        return this.userHasAnyValidPermission(userPermissions, [
          readMostSensitiveKpis,
          readSensitiveKpis,
          readNonSensitiveKpis
        ]);
      }
      return false;
    },
    /** Function to check if a user can write KPI values
     * @param {object} user                           A user object
     * @param {number} kpiSensitivityLevel            The KPI sensitivity level to check against
     * @param {object} userPermissions                Array-like object containing the user permissions
     * @returns                                       boolean: true if the user can write. Otherwise, false
     */
    canUserWriteKpiSensitivityLevel(user, kpiSensitivityLevel, userPermissions) {
      if (this.isUserAdmin(user)) return true;
      switch (kpiSensitivityLevel) {
        case kpiSuperSensitiveLevel:
          return this.userHasAnyValidPermission(userPermissions, [writeSuperSensitiveKpis]);
        case kpiMostSensitiveLevel:
          return this.userHasAnyValidPermission(userPermissions, [writeMostSensitiveKpis]);
        case kpiSensitiveLevel:
          return this.userHasAnyValidPermission(userPermissions, [
            writeMostSensitiveKpis,
            writeSensitiveKpis
          ]);
        case kpiNonSensitiveLevel:
          return this.userHasAnyValidPermission(userPermissions, [
            writeMostSensitiveKpis,
            writeSensitiveKpis,
            writeNonSensitiveKpis
          ]);
        default:
          return false;
      }
    },
    /** Check if the current user can access the KPI details page based on the sensitivity of the KPI
     * @param {number} kpiSensitivityLevel            The KPI sensitivity level to check against
     * @returns                                       boolean: true if the user can access. Otherwise, false
     */
    currentUserCanAccessKpiDetails(kpiSensitivityLevel) {
      return this.canUserAccessKpiSensitivityLevel(
        this.userProfile,
        kpiSensitivityLevel,
        this.userPermissions
      );
    },
    /** Check if the current user can write KPI values based on the sensitivity of the KPI
     * @param {number} kpiSensitivityLevel            The KPI sensitivity level to check against
     * @returns                                       boolean: true if the user can write. Otherwise, false
     */
    currentUserCanWriteKpiDetails(kpiSensitivityLevel) {
      return this.canUserWriteKpiSensitivityLevel(
        this.userProfile,
        kpiSensitivityLevel,
        this.userPermissions
      );
    },
    /** Function to check if a user can access Investor Reporting dashboards
     * @param {object} user                           A user object
     * @returns                                       boolean: true if the user can access. Otherwise, false
     */
    canUserAccessInvestorReporting(user) {
      return this.isUserAdmin(user) || user.permissions.includes("write_investor_reporting");
    },
    /** Function to check if a user can read investor reporting
     * @param {object} user                           A user object
     * @returns                                       boolean: true if the user can read. Otherwise, false
     */
    canUserReadInvestorReporting(user) {
      return this.isUserAdmin(user) || user.permissions.includes("read_investor_reporting");
    }
  }
};
