import { Buffer } from "buffer";
export const routeNames = {
  personalDashboard: "personalDashboard",
  customDashboard: "customDashboard"
};

export const localStorageKeys = {
  financialDashboard: "financial_dashboard_selected_view"
};

/**
 * Will try to cast route param values to the type passed in mapping. Default type is String, and can be omitted
 * @param {Object} mapping key-value pair between route param name and the type it should be cast to
 * @returns Object with casted route params
 */
export function castParams(mapping) {
  return function (route) {
    const paramValue = Object.entries(route.params); // [[param1, "1"], [param2, "hello"]]
    const castedParamValue = paramValue.map(([name, value]) => {
      const mapFn = mapping[name];
      try {
        return [name, mapFn ? mapFn(value) : value];
      } catch (e) {
        throw Error(`Cannot convert value '${value}' to '${mapFn}', for route '${route.name}'`);
      }
    }); // [[param1, 1], [param2, "hello"]]
    return Object.fromEntries(castedParamValue); // {param1: 1, param2: "hello"}
  };
}

/**
 * Takes a string as input and return it in the form "<text> (<number>)"
 * or "<text> (<number + 1>)" if string already has a number
 * @param {String} text any string
 * @returns String with the format `<text> (<number>)`. The number in the paranthesis will be incremented
 * Ex.: "Report June"      -> "Report June (1)"
 *      "Report June (12)" -> "Report June (13)"
 */
export function incrementString(text) {
  if (typeof text !== "string") return "";

  const parseNumberInParenthesis = t => {
    t = t.trim();
    return +t.slice(1, t.length - 1);
  };

  // Regex explanation:
  // \s*\(\d*\)
  //   \s* matches any whitespace characters 0..inf times
  //   \( matches the character '('
  //     \d* matches digits 0..inf times
  //   \) matches the character ')'
  const regex = /(\s*\(\d*\))$/;

  return regex.test(text)
    ? text.replace(regex, (_, t) => ` (${parseNumberInParenthesis(t) + 1})`.padStart(t.length, 0))
    : `${text} (1)`;
}

/**
 * Generates a unique ID.
 * @returns {string} - The unique ID.
 */
export function generateUniqueId() {
  return Math.random().toString(36).slice(2);
}

/**
 * Merges 2 lists of objects into one list, based on the "id" field in the objects
 * Objects from `list1` will be included as fallback
 * @param {Array} list1 first list of objects containing at least the "id" field
 * @param {Array} list2 second list of objects containing at least the "id" field
 * @returns a list of objects with merged fields from list1 and list2 by the "id" field
 */
export function mergeListOfObjects(list1, list2) {
  const map = list2.reduce((acc, obj) => {
    acc[obj.id] = obj;
    return acc;
  }, {});

  return list1.map(obj1 => Object.assign({}, map[obj1.id], obj1));
}

/**
 * Downloads a file from the response, and renames it to the given name.
 * If the response type is not a blob, no file will be downloaded
 *
 * @param {*} res  axios response with the 'blob' `responseType`
 * @param {*} name the desired file name
 */
export function downloadFile(res, name) {
  // Ensure response is of type blob
  // The axios request should have the following option to have the 'blob' `responseType`
  // { responseType: "blob" }
  if (res.config.responseType !== "blob") {
    console.error("response is not of responseType blob");
    return;
  }

  const url = window.URL.createObjectURL(res.data);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();

  // deallocate resources
  URL.revokeObjectURL(url);
  link.remove();
}

/**
 * Shallow compares 2 objects, ignoring the order of the keys.
 * Ex:
 * `equalsShallow({"a": 1, "z": 2}, {"z": 2, "a": 1})` // true
 * `equalsShallow({"a": {"a": 1, "z": 2}, "z": 2}, {"z": 2, "a": {"z": 2, "a": 1}})` // false
 * @param {Object} a first object to compare
 * @param {Object} b second object to compare
 */
export function equalsShallow(a, b) {
  Object.entries(a).sort().toString() === Object.entries(b).sort().toString();
}

/**
 * Converts a base64 string to a json object
 * @param {string} base64String base64 string to decode
 * @returns decoded base64 string to json
 */
export function base64ToJson(base64String) {
  const json = Buffer.from(base64String, "base64").toString();
  return JSON.parse(json);
}

/**
 * Converts a json object to a base64 string
 * @param {Object} object json object to encode
 * @returns base64 string from the json object
 */
export function jsonToBase64(object) {
  const json = JSON.stringify(object);
  return Buffer.from(json).toString("base64");
}

/**
 * Converts a string to a base64 string
 * @param {string} str string to encode
 * @returns base64 string from the string
 */
export function stringToBase64(str) {
  return Buffer.from(str).toString("base64");
}

/**
 * Converts a base64 string to a string
 * @param {string} base64String base64 string to decode
 * @returns decoded base64 string to string
 */
export function base64ToString(base64String) {
  return Buffer.from(base64String, "base64").toString();
}

/**
 * Concatinate a with b but ignore duplicates defined by the predicate.
 * Default predicate compares preimitive types
 * @param {Array} a array of any object types
 * @param {Array} b array of any object types to be merged with
 * @param {Function} predicate to check if two items are equal
 * @returns a and b merged, but without duplicates, based on the predicate
 */
export function mergeArrays(a, b, predicate = (a, b) => a === b) {
  const c = [...a]; // copy to avoid side effects
  // add all items from B to copy C if they're not already present
  b.forEach(bItem => (c.some(cItem => predicate(bItem, cItem)) ? null : c.push(bItem)));
  return c;
}

/**
 * Calculate the variance between actual and compareTo numbers
 * @param {Number} actual current number (base)
 * @param {Number} compareTo number to compare against
 * @param {Boolean} asPercent true if the variance should be returned as percent
 * @returns Number either absolute or percent variance
 */
export function getVariance(actual, compareTo, asPercent = true, reversedVariance = false) {
  if (actual == null || compareTo == null) {
    return null;
  }

  if (!asPercent) {
    return actual - compareTo;
  }

  if (!reversedVariance && (compareTo === 0 || Math.sign(actual) * Math.sign(compareTo) < 0)) {
    return actual > 0 ? 999999999 : actual < 0 ? -999999999 : 0;
  }

  const variance = reversedVariance
    ? (1 - (actual + compareTo) / Math.abs(compareTo)) * 100
    : ((actual - compareTo) / Math.abs(compareTo)) * 100;
  // Round to 1 digit after the comma
  return Math.round((variance + Number.EPSILON) * 10) / 10;
}

/**
 * Gets the logo file name for a selected theme mode
 * @param {String} mode should be either dark or white
 * @returns formated string with the custom logo name
 */
export function getCustomLogoName(mode) {
  return `customer_logo_${mode}`;
}

export function checkSearchValue(item, searchValue) {
  return item.name.toLowerCase().includes(searchValue.toLowerCase());
}

export function mapAggregatesToNewSchema(aggregates) {
  const isOldSchema =
    typeof aggregates === "object" && !Array.isArray(aggregates) && aggregates !== null;

  if (isOldSchema) {
    return aggregates.yearToDate.map(agg => ({
      kpiId: agg.kpiId,
      ytd: {
        planningValue: agg.aggregates.planningSum,
        lastYearValue: agg.aggregates.lastYearHistorySum,
        forecastValue: agg.forecast?.historySum,
        actualValue: agg.aggregates.historySum,
        averageValue: null,
        averagePlanningValue: null
      },
      fullyear: {
        planningValue: agg.projection.planningSum,
        lastYearValue: null,
        forecastValue: null
      },
      ltm: {
        actualValue: null,
        planningValue: null
      }
    }));
  }

  return aggregates || [];
}

/**
 * Returns the sort logic for kpis used in Add Kpis Modal which orders
 * first based if kpi's id is included in preselectedKpiIds and then alphabetically
 * @param {Object} a left hand side kpi for comparison
 * @param {Object} b right hand side kpi for comparison
 * @param {Array} preselectedKpiIds array of kpi ids which are preselected and should be prio. in this ordering
 * @returns the list ordered based on condition if kpi's id is included in preselectedKpiIds and then alphabetically
 */
export function sortFunctionForAddKpiModal(a, b, preselectedKpiIds) {
  // Order the preselected first and then alphabetically
  if (preselectedKpiIds.includes(a.id) && !preselectedKpiIds.includes(b.id)) return -1;
  if (!preselectedKpiIds.includes(a.id) && preselectedKpiIds.includes(b.id)) return 1;
  if (a.name < b.name) return -1;
  else if (a.name > b.name) return 1;
  return 0;
}

export function mouseX(evt) {
  if (evt.pageX) {
    return evt.pageX;
  } else if (evt.clientX) {
    return (
      evt.clientX +
      (document.documentElement.scrollLeft
        ? document.documentElement.scrollLeft
        : document.body.scrollLeft)
    );
  } else {
    return null;
  }
}

export function mouseY(evt) {
  if (evt.pageY) {
    return evt.pageY;
  } else if (evt.clientY) {
    return (
      evt.clientY +
      (document.documentElement.scrollTop
        ? document.documentElement.scrollTop
        : document.body.scrollTop)
    );
  } else {
    return null;
  }
}
