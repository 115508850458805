<template functional>
  <div
    class="caret-container"
    :style="{ fontSize: `${props.size === 'sm' ? 12 : 14}px` }">
    <b-icon-caret-down-fill class="dropdown-caret" />
    <b-icon-caret-down class="dropdown-caret-fill" />
  </div>
</template>

<script>
export default {
  name: "DropdownCaret",
  props: {
    size: {
      type: String,
      default: "md"
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/colors.module.scss";

.dropdown,
.btn {
  .caret-container {
    display: inline-flex;
    align-items: center;
    vertical-align: top;
    margin-top: 1px;
  }

  .dropdown-caret,
  .dropdown-caret-fill {
    color: var(--dropdown-caret-color);
  }

  .dropdown-caret {
    display: none;
  }

  .dropdown-caret-fill {
    display: inline-flex;
  }
}

.dropdown:hover,
.dropdown.show,
.btn:hover,
.btn.show {
  .dropdown-caret,
  .dropdown-caret-fill {
    color: var(--highlight-orange);
  }

  .dropdown-caret {
    display: inline-flex;
  }

  .dropdown-caret-fill {
    display: none;
  }

  button.disabled {
    .dropdown-caret,
    .dropdown-caret-fill {
      color: inherit;
    }

    .dropdown-caret {
      display: none;
    }

    .dropdown-caret-fill {
      display: inline-flex;
    }
  }
}

.dropdown {
  button.disabled {
    .dropdown-caret,
    .dropdown-caret-fill {
      color: inherit;
    }

    .dropdown-caret {
      display: none;
    }

    .dropdown-caret-fill {
      display: inline-flex;
    }
  }
}
</style>
