import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { currentUserCanAccessRoute } from "@/mixins/PermissionsMixin";
import { castParams } from "vw/utils.js";

Vue.use(VueRouter);

const kpiDetail = () => import("@/views/reporting/KpiDetail.vue");
const okrCategoryUser = () => import("@/views/OkrCategoryUser.vue");
const uiPatterns = () => import("@/views/UIPatterns.vue");

// IMPORTANT: When route names are updated it must also be adapted in the PermissionsMixin!
const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/login/mfa",
    name: "loginMFA",
    component: () => import("@/views/LoginMFA")
  },
  {
    path: "/forgetpassword",
    name: "forgetpassword",
    component: () => import("@/views/ForgetPassword.vue")
  },
  {
    path: "/password/reset/confirm/:uid/:token",
    name: "resetPassword",
    component: () => import("@/views/ResetPassword.vue"),
    props: true
  },
  {
    path: "/oauth",
    name: "oauth",
    component: () => import("@/views/oauth/OAuthRedirect.vue")
  },
  {
    path: "/iframelogin",
    name: "iframeLogin",
    component: () => import("@/views/oauth/IFrameLogin.vue")
  },
  {
    path: "/export/custom-dashboard/:id",
    name: "exportCustomDashboard",
    component: () => import("@/views/export/ExportCustomDashboard.vue"),
    props: castParams({ id: Number })
  },
  {
    path: "/print",
    name: "printPage",
    component: () => import("@/views/PrintPage.vue"),
    children: [
      {
        path: "investor-reporting/:reportId",
        name: "printInvestorReporting",
        component: () => import("@/views/print/PrintInvestorReporting.vue"),
        props: castParams({ reportId: Number })
      },
      {
        path: "reporting/custom-dashboard/:id",
        name: "printCustomDashboard",
        component: () => import("@/views/print/PrintCustomDashboard.vue"),
        props: castParams({ id: Number })
      }
    ]
  },
  {
    path: "/",
    component: () => import("@/views/Home.vue"),
    children: [
      {
        path: "/",
        redirect: { name: "personalDashboard" }
      },
      {
        path: "/my-dashboard",
        name: "personalDashboard",
        component: () => import("@/views/PersonalDashboard.vue")
      },
      {
        path: "/planning",
        name: "planning",
        redirect: { name: "financialPlanning" }
      },
      {
        path: "/planning/financial-planning/:categoryId?",
        name: "financialPlanning",
        component: () => import("@/views/planning/FinancialPlanning.vue"),
        props: true
      },
      {
        path: "/planning/operational-planning",
        name: "operationalPlanning",
        component: () => import("@/views/planning/OperationalPlanning.vue")
      },
      {
        path: "/planning/liquidity-planning",
        name: "liquidityPlanning",
        component: () => import("@/views/planning/LiquidityPlannerReactPage.vue")
      },
      {
        path: "planning/liquidity-planning/headcount/scenario/:scenarioId",
        name: "headcountScenario",
        component: () => import("@/views/planning/HeadcountScenarioReactPage.vue"),
        props: castParams({ scenarioId: Number })
      },
      {
        path: "/forecasting/category/:categoryId",
        name: "forecastingOverview",
        component: () => import("@/views/ForecastingOverview.vue"),
        props: castParams({ categoryId: Number })
      },
      {
        path: "/forecasting/:id",
        name: "forecastingView",
        component: () => import("@/components/forecast/ForecastingView.vue"),
        props: castParams({ id: Number })
      },
      {
        path: "/reporting",
        name: "reporting",
        redirect: { name: "operationalDashboard" }
      },
      // Used in case the user have a permission to add custom dashboards
      // But doesn't have permission for either operational or financial dashboards, or any custom dashboard
      {
        path: "/reporting/custom",
        name: "reportingCustom"
      },
      {
        path: "/reporting/operational-dashboard",
        name: "operationalDashboard",
        component: () => import("@/views/reporting/OperationalDashboard.vue"),
        props: true
      },
      {
        path: "/reporting/strategic-dashboard",
        name: "strategicDashboard",
        component: () => import("@/views/reporting/StrategicDashboard.vue"),
        props: true
      },
      {
        path: "/reporting/financial-dashboard/:categoryId?",
        name: "financialDashboard",
        component: () => import("@/views/reporting/FinancialDashboard.vue"),
        props: true
      },
      {
        path: "/reporting/custom-dashboard-helper",
        name: "customDashboardHelper",
        component: () => import("@/views/CustomDashboardHelper"),
        props: true
      },
      {
        path: "/reporting/custom-dashboard/:id?",
        name: "customDashboard",
        props: true,
        component: () => import("@/views/reporting/CustomDashboard.vue")
      },
      {
        path: "/reporting/kpi-detail/:kpiId/charts/:chartId?",
        name: "kpiCharts",
        component: kpiDetail,
        props: true
      },
      {
        path: "/reporting/kpi-detail/:kpiId/:reportType?",
        name: "kpiDetail",
        component: kpiDetail,
        props: true
      },
      {
        path: "/reporting/kpi-detail/:kpiId/recommendations/:recommendationsType?",
        name: "kpiRecommendations",
        component: kpiDetail,
        props: true
      },
      {
        path: "/reporting/kpi-detail/:kpiId/:reportType/:deepdrillId?",
        name: "kpiDeepdrills",
        component: kpiDetail,
        props: true
      },
      {
        path: "/investor-reporting/:reportId?/:tab?",
        name: "investorReporting",
        component: () => import("@/views/investorReporting/InvestorReporting.vue"),
        props: castParams({ reportId: Number })
      },
      {
        path: "/integration/:viewName?",
        name: "integration",
        component: () => import("@/views/IntegrationDashboard.vue")
      },
      {
        path: "/integration/datev/wizard",
        name: "datevIntegrationWizard",
        component: () => import("@/views/DatevOverviewIntegrationWizard.vue"),
        props: true
      },
      {
        path: "/integration/:name/wizard",
        name: "integrationWizard",
        component: () => import("@/views/IntegrationWizard.vue"),
        props: true
      },
      {
        path: "/query-builder",
        name: "queryBuilder",
        component: () => import("@/views/KpiBuilderReactPage.vue")
      },
      {
        path: "/objectives",
        name: "objectives",
        redirect: { name: "objectivesOverview" }
      },
      {
        path: "/objectives/overview/:categoryId?",
        name: "objectivesOverview",
        component: () => import("@/views/OkrOverview.vue")
      },
      {
        path: "/objectives/changelog",
        name: "changeLogView",
        component: () => import("@/views/ChangeLogView.vue"),
        props: true
      },
      {
        path: "/objectives/okrteamanalytics",
        name: "okrTeamAnalytics",
        component: () => import("@/views/OkrTeamAnalytics.vue")
      },
      {
        path: "/objectives/corporate",
        name: "objectivesCorporate",
        component: okrCategoryUser,
        props: { category: 1 }
      },
      {
        path: "/objectives/okrlistview",
        name: "okrListView",
        component: () => import("@/views/OkrListView.vue"),
        props: true
      },
      {
        path: "/objectives/teams/:category?",
        name: "objectivesTeams",
        component: okrCategoryUser,
        props: route => ({ category: Number(route.params.category) })
      },
      {
        path: "/objectives/users/:user",
        name: "objectivesUser",
        component: okrCategoryUser,
        props: true
      },
      {
        path: "/objectives/users/teams/:user",
        name: "objectivesUserTeams",
        component: okrCategoryUser,
        props: true
      },
      {
        path: "/objectives/id/:id?",
        name: "objectiveDetail",
        component: () => import("@/views/ObjectiveDetail.vue"),
        props: true
      },
      {
        path: "/objectives/scheduler",
        name: "okrScheduler",
        component: () => import("@/views/OkrScheduler.vue")
      },
      {
        path: "/data-management/data-input",
        name: "dataInput",
        component: () => import("@/views/dataUpload/DataInput.vue")
      },
      {
        path: "/data-management",
        name: "dataManagement",
        redirect: { name: "integration" }
      },
      {
        path: "/settings",
        name: "settings",
        redirect: { name: "userManagement" }
      },
      {
        path: "/settings/user-management",
        name: "userManagement",
        component: () => import("@/views/settings/UserManagement.vue")
      },
      {
        path: "/settings/holding-management",
        name: "manageHolding",
        component: () => import("@/views/holding/ManageHolding.vue")
      },
      {
        path: "/settings/currency-settings",
        name: "currencySettings",
        component: () => import("@/views/settings/CurrencySettings.vue")
      },
      {
        path: "/settings/kpi-management",
        name: "kpiManagement",
        component: () => import("@/views/settings/KPIManagement.vue")
      },
      {
        path: "/settings/kpi-glossary",
        name: "kpiGlossary",
        component: () => import("@/views/settings/KPIGlossary.vue")
      },
      {
        path: "/settings/okr-settings",
        name: "okrSettings",
        component: () => import("@/views/OkrSettings.vue")
      },
      {
        path: "/settings/chatgpt-settings",
        name: "chatgptSettings",
        component: () => import("@/views/ChatgptSettings.vue")
      },
      {
        path: "/settings/two-factor-auth",
        name: "twoFactorAuth",
        component: () => import("@/views/settings/OTPSettings.vue")
      },
      {
        path: "/settings/organizational-structure",
        name: "organizationalStructure",
        component: () => import("@/views/settings/OrganizationalStructure.vue")
      },
      {
        path: "/settings/company-settings",
        name: "companySettings",
        component: () => import("@/views/settings/CompanySettings.vue")
      },
      {
        path: "/uipatterns",
        name: "uiPatterns",
        component: uiPatterns
      },
      {
        path: "/uipatterns2",
        name: "uiPatterns2",
        component: uiPatterns
      },

      // holding
      {
        path: "/holding-reporting/:companyId/:reportId?/:tab?",
        name: "holdingReporting",
        component: () => import("@/views/holding/HoldingReporting.vue"),
        props: castParams({ companyId: Number, reportId: Number })
      },
      {
        path: "/portfolio",
        name: "managePortfolio",
        component: () => import("@/views/holding/ManagePortfolio.vue")
      },
      {
        path: "/kpi-comparison/:kpiId?/:tab?",
        name: "kpiComparison",
        props: true,
        component: () => import("@/views/holding/KpiComparison.vue")
      },

      // wild card
      {
        path: "/404",
        alias: "*",
        name: "404",
        component: () => import("@/views/PageDoesNotExist.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// The following routes will not be remembered for the back button routing

router.beforeEach(async (to, from, next) => {
  // Redirect to dashboard if user is logged in and tries to access login page
  if (to.path === "/login" && store.getters.isLoggedIn) {
    return next("/my-dashboard");
  }
  // Re-route to login if the user tries to access a site with login required
  const privatePage = !publicPages.filter(item => to.path.includes(item)).length > 0;
  // always allow if public page
  if (!privatePage) {
    next();
    return;
  }
  // reditect to login if private page and user not logged-in
  if (privatePage && !store.getters.isLoggedIn) {
    store.dispatch("setLoadingRedirect", to);
    next("/login");
    return;
  }

  // the dispatch will do the check only once and store the result in the vuex store
  // currently many components depend on the user profile, that's why we have to call it here
  // before we'll access any view-component and start fetching data
  await store.dispatch("loadUserProfile");
  const menu = await store.dispatch("loadMenu");
  // allways allow 404, to avoid infinity loops
  if (to.name === "404") {
    next();
    return;
  }

  // check if the requested route is in the list of available routes
  if (menu.flatMap[to.name] || currentUserCanAccessRoute(to.name)) {
    next();
    return;
  }

  // redirect to the first available item in the menu
  const firstMenuItemLink = menu.items[0]?.to;
  if (firstMenuItemLink?.name) {
    next(firstMenuItemLink);
    return;
  }

  // redirect to 404 otherwise
  next({ name: "404" });
  return;
});

export default router;

const publicPages = [
  "/login",
  "/password/reset/confirm",
  "/forgetpassword",
  "/print/investor-reporting",
  "/print/reporting/custom-dashboard",
  "/iframelogin"
];
